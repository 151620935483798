<template>
  <AppSidebar fixed>
    <SidebarHeader/>
    <SidebarForm/>
    <SidebarNav :navItems="nav"></SidebarNav>
    <SidebarFooter/>
    <SidebarMinimizer/>
  </AppSidebar>
</template>
<script>
import { SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav } from '@coreui/vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  components: {
    AppSidebar,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data () {
    return {
      clientName: 'loading...'
    }
  },
  computed: {
    ...mapGetters({
      lastSearch: 'global/lastSearch',
      currentClient: 'global/currentClient',
      currentStrategy: 'global/currentStrategy',
    }),
    nav () {
      let navItems = []

      if (this.lastSearch) {
        navItems.push({
          name: 'Back to Search',
          url: `/clients/search/${this.lastSearch}`,
          icon: 'cui-arrow-circle-left'
        })
      } else {
        navItems.push({
          name: 'Back to Search',
          url: `/clients/search`,
          icon: 'cui-arrow-circle-left'
        })
      }

      navItems.push({
        title: true,
        name: this.clientName,
        class: '',
        wrapper: {
          element: '',
          attributes: {}
        }
      })
      navItems.push({
        name: 'Client Info',
        url: `/clients/${this.$route.params.id}/info`,
        icon: 'icon-user'
      })

      if (this.$can('read', 'strategies')) {
        navItems.push({
          name: 'Strategies',
          url: `/clients/${this.$route.params.id}/strategies`,
          icon: 'icon-magic-wand'
        })
      }

      if (this.currentStrategy.id > 0) {
        navItems.push(
        {
          title: true,
          name: this.currentStrategy.name,
          class: '',
          wrapper: {
            element: '',
            attributes: {}
          }
        })

        navItems.push(
        {
          name: 'Inputs',
          url: `/clients/${this.$route.params.id}/strategies/${this.$route.params.strategyId}/input`,
          icon: 'icon-calculator'
        })

        navItems.push(
        {
          name: 'Debt Sequence',
          url: `/clients/${this.$route.params.id}/strategies/${this.$route.params.strategyId}/sequence`,
          icon: 'icon-clock'
        })

        navItems.push(
        {
          name: 'Policy Design',
          url: `/clients/${this.$route.params.id}/strategies/${this.$route.params.strategyId}/design`,
          icon: 'icon-equalizer'
        })

        navItems.push(
        {
          name: 'Strategy',
          url: `/clients/${this.$route.params.id}/strategies/${this.$route.params.strategyId}/strategy`,
          icon: 'icon-magic-wand'
        })

        navItems.push(
        {
          name: 'Summary',
          url: `/clients/${this.$route.params.id}/strategies/${this.$route.params.strategyId}/summary`,
          icon: 'icon-book-open'
        })
      }

      return navItems
    },
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  },
  methods: {
    loadClientName () {
      if (this.currentClient.id > 0) {
        this.clientName = `${this.currentClient.primaryContact.firstName} ${this.currentClient.primaryContact.lastName}`
      }
    }
  },
  created() {
    this.loadClientName()
  },
  watch: {
    currentClient: {
      handler: function (client) {
        this.loadClientName()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
